import React, { useEffect, useState } from "react";
import "./index.css";
import LOGO from "@images/Logo.png";
import Pic1 from "@images/marketing/Picture1.png";
import Pic2 from "@images/marketing/Picture2.png";
import Pic3 from "@images/marketing/Picture3.png";
import Pic4 from "@images/marketing/Picture4.png";
import Pic5 from "@images/marketing/Picture5.png";
import Pic12 from "@images/marketing/Picture12.png";
import objectifsList2 from "@images/marketing/objectifsList2.png";
import MarketingDigital from "@images/marketing/marketing_compagne.png";
import Référencements from "@images/marketing/Référencements.png";
import { Fonts } from "@common";
import { TbPointFilled } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const Marketing = () => {
  const [transformValue, setTransformValue] = useState(200);

  useEffect(() => {
    const handleResize = () => {
      const newTransformValue = window.innerWidth / 200;
      setTransformValue(newTransformValue);
    };

    window.addEventListener("resize", handleResize);

    // Initial call to set the transform value based on initial window size
    handleResize();

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navigate = useNavigate();
  return (
    <div
      className="marketing__resulat_container"
      style={{ position: "relative" }}
    >
      <div className="icons__fixed_left__marketing_digital">
        <img
          alt="pic1"
          src={Pic1}
          className="pic1__marketing_compagne"
          style={{ left: `${transformValue + 4}rem` }}
        />
      </div>
      <div className="logo_section" onClick={() => navigate(-1)}>
        <img
          src={LOGO}
          alt="logo"
          className="logo_image_marketing_compagne"
          style={{ cursor: "pointer" }}
        />
      </div>
      <img
        style={{ position: "absolute", zIndex: 1 }}
        alt="pic2"
        src={Pic2}
        className="pic2__fixed_image"
      />
      <div
        className="marketing_digital_section"
        style={{ position: "relative", zIndex: 3 }}
      >
        {/* <div className="first__wrapper"></div> */}
        <p
          className="digital_maketing_title_eeafrica"
          style={{
            color: "rgb(214 72 79)",
            fontFamily: Fonts().primarySemiBold,
            textAlign: "start",
          }}
        >
          3E Africa : Marketing - Marketing digital
        </p>
        <p
          className="digital_marketing_descreption_text_eeafrica"
          style={{ fontFamily: Fonts().primaryRegular }}
        >
          Que ce soit pour{" "}
          <strong style={{ fontFamily: Fonts().primaryBold }}>
            augmenter votre part de marché
          </strong>{" "}
          ou{" "}
          <strong style={{ fontFamily: Fonts().primaryBold }}>
            diversifier votre offre
          </strong>
          , la{" "}
          <strong style={{ fontFamily: Fonts().primaryBold }}>stratégie</strong>{" "}
          à adopter pour favoriser la croissance de votre entreprise passe par{" "}
          <strong style={{ fontFamily: Fonts().primaryBold }}>
            le marketing digital
          </strong>
          .
        </p>
        <br />
        <p
          className="digital_marketing_descreption_text_eeafrica2"
          style={{ fontFamily: Fonts().primarySemiBold }}
        >
          Vous identifiez un ou plusieurs de ces objectifs ?{" "}
        </p>
        <div className="section__items_eeafrica">
          <div className="section__single_item_eeafrica">
            {/* <TbPointFilled style={{ fontSize: 10 }} /> */}
            <p
              className="item__eeafrica"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              •{" "}
              <span style={{ marginLeft: 12 }}>
                Acquérir de nouveaux clients{" "}
              </span>
            </p>
            <p
              className="item__eeafrica"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              • <span style={{ marginLeft: 12 }}>Fidéliser des clients </span>
            </p>
            <p
              className="item__eeafrica"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              • <span style={{ marginLeft: 12 }}>Augmenter vos ventes</span>
            </p>
            <p
              className="item__eeafrica"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              •{" "}
              <span style={{ marginLeft: 12 }}>
                Conquérir de nouveaux marchés{" "}
              </span>
            </p>
            <p
              className="item__eeafrica"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              • <span style={{ marginLeft: 12 }}>Informer vos clients </span>
            </p>
            <p
              className="item__eeafrica"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              •{" "}
              <span style={{ marginLeft: 12 }}>
                Améliorer la notoriété de votre marque{" "}
              </span>
            </p>
            <p
              className="item__eeafrica"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              •{" "}
              <span style={{ marginLeft: 12 }}>
                Promouvoir l’innovation (nouveaux produits){" "}
              </span>
            </p>
          </div>
          <br />
          <p
            className="eeafrica_alors_lancez_une_campagne"
            style={{ fontFamily: Fonts().primaryBold, color: "#df5c57" }}
          >
            Alors lancer une campagne marketing !
          </p>
          <p
            className="eeafrica_alors_lancez_une_campagne"
            style={{ fontFamily: Fonts().primaryRegular }}
          >
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              3E Africa vous accompagne{" "}
            </strong>{" "}
            dans votre projet Marketing{" "}
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              pour définir{" "}
            </strong>
            avec vous{" "}
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              la meilleure stratégie
            </strong>{" "}
            à adopter{" "}
            <strong style={{ fontFamily: Fonts().primaryBold }}>et</strong> tout
            au long de votre campagne marketing pour{" "}
            <strong style={{ fontFamily: Fonts().primaryBold }}>
              atteindre vos objectifs
            </strong>
            .
          </p>
          <p
            className="eeafrica_alors_lancez_une_campagne"
            style={{ fontFamily: Fonts().primaryRegular }}
          >
            Pour mieux répondre à vos attentes, nous opérons de 2 façons
            différentes.
          </p>
          <div className="eeafrica__second_items">
            <p
              className="eeafrica_second_item__text"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              <em>•</em>
              <span>
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Prestation de conseil :
                </strong>{" "}
                <span>
                  intervention limitée dans le temps, facturée au temps passé.
                </span>
              </span>
            </p>
            <p
              className="eeafrica_second_item__text"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              <em>•</em>
              <span>
                <strong style={{ fontFamily: Fonts().primaryBold }}>
                  Accompagnement régulier par abonnement :
                </strong>{" "}
                <span>
                  approche innovante à grande valeur ajoutée permettant
                  d’accompagner l’entreprise dans la durée avec un modèle
                  économique adapté.
                </span>
              </span>
            </p>
          </div>
          <br />
          <br />
          <p
            className="eeafrica__nos__missions"
            style={{ fontFamily: Fonts().primaryBold }}
          >
            Nos missions{" "}
          </p>
          <div className="eeafrica__third__list_items">
            <div className="eeafrica__third__list_items_section">
              <span
                className="single__item__nos_missions"
                style={{ fontFamily: Fonts().primaryBold, padding: "7px 18px" }}
              >
                1
              </span>
              <p
                className="eeafrica_nos_missions__p_text"
                style={{ fontFamily: Fonts().primaryBold }}
              >
                Être présent à chaque étape de la campagne marketing
              </p>
            </div>
            <div className="eeafrica__third__list_items_section">
              <span
                className="single__item__nos_missions"
                style={{ fontFamily: Fonts().primaryBold }}
              >
                2
              </span>
              <p
                className="eeafrica_nos_missions__p_text"
                style={{ fontFamily: Fonts().primaryBold }}
              >
                Définir l’identité visuelle de votre entreprise
              </p>
            </div>

            <div className="eeafrica__third__list_items_section">
              <span
                className="single__item__nos_missions"
                style={{ fontFamily: Fonts().primaryBold }}
              >
                3
              </span>
              <p
                className="eeafrica_nos_missions__p_text"
                style={{ fontFamily: Fonts().primaryBold }}
              >
                Réaliser des vidéos
              </p>
            </div>
            <div className="eeafrica__third__list_items_section">
              <span
                className="single__item__nos_missions"
                style={{ fontFamily: Fonts().primaryBold }}
              >
                4
              </span>
              <p
                className="eeafrica_nos_missions__p_text"
                style={{ fontFamily: Fonts().primaryBold }}
              >
                Créer votre site web/application mobile
              </p>
            </div>
            <div className="eeafrica__third__list_items_section">
              <span
                className="single__item__nos_missions"
                style={{ fontFamily: Fonts().primaryBold }}
              >
                5
              </span>
              <p
                className="eeafrica_nos_missions__p_text"
                style={{ fontFamily: Fonts().primaryBold }}
              >
                Faire la gestion de contenu
              </p>
            </div>
            <div className="eeafrica__third__list_items_section">
              <span
                className="single__item__nos_missions"
                style={{ fontFamily: Fonts().primaryBold }}
              >
                6
              </span>
              <p
                className="eeafrica_nos_missions__p_text"
                style={{ fontFamily: Fonts().primaryBold }}
              >
                Gérer le référencement de votre site
              </p>
            </div>
          </div>
          <br />
          <div className="eeafrica__first__list__image">
            <p
              className="first_list_image_text"
              style={{ fontFamily: Fonts().primaryRegular, color: "#df5c57" }}
            >
              <span
                className="etre_present__vos"
                style={{ fontFamily: Fonts().primaryBold }}
              >
                Être présent à vos côtés à chaque étape de la campagne marketing
              </span>
            </p>
            <img
              className="marketing_digital_image_list_pic"
              alt="marketing_compagne"
              src={MarketingDigital}
            />
          </div>
        </div>
      </div>
      <div
        className="Definir_lidentite_visuelle_de_votre_entreprise first__definir"
        style={{ position: "relative" }}
      >
        <div className="image__fixed___">
          <img
            src={Pic5}
            className="image_fixed_3___"
            style={{
              left: `${
                transformValue +
                (window.innerWidth <= 1310
                  ? window.innerWidth <= 1000
                    ? window.innerWidth <= 540
                      ? 22
                      : 17
                    : 12
                  : 0)
              }rem`,
            }}
          />
        </div>
        <div className="wrapper__div___">
          <p
            className="Definir_lidentite_visuelle_de_votre_entreprise_text"
            style={{ color: "#df5c57", fontFamily: Fonts().primaryBold }}
          >
            Réaliser des vidéos
          </p>
          <br />
          <p
            className="role_cle_dans___text"
            style={{ fontFamily: Fonts().primaryRegular }}
          >
            Le marketing vidéo est un élément clé dans le monde du marketing
            digital, un moyen puissant pour les marques de se{" "}
            <strong style={{ fontWeight: "bold", fontFamily: "Roboto" }}>
              connecter avec leur public, d'augmenter leur visibilité,
              d'améliorer leur engagement
            </strong>{" "}
            et d’atteindre leurs objectifs de marketing. Avec une planification
            et une exécution soignées, les vidéos peuvent captiver votre
            audience, renforcer la notoriété de votre marque et stimuler les
            conversions.
          </p>
          <br />
          <p
            className="role_cle_dans___text"
            style={{ fontFamily: Fonts().primaryRegular }}
          >
            Voici quelques éléments clés pour réussir dans le marketing vidéo :
          </p>
          <p
            className="item__eeafrica"
            style={{ fontFamily: Fonts().primaryRegular }}
          >
            •{" "}
            <span style={{ marginLeft: 12 }}>Identifier l’audience cible</span>
          </p>
          <p
            className="item__eeafrica"
            style={{ fontFamily: Fonts().primaryRegular }}
          >
            • <span style={{ marginLeft: 12 }}>Définir les objectifs</span>
          </p>
          <p
            className="item__eeafrica"
            style={{ fontFamily: Fonts().primaryRegular }}
          >
            •{" "}
            <span style={{ marginLeft: 12 }}>Choisir le bon type de vidéo</span>
          </p>
          <p
            className="item__eeafrica"
            style={{ fontFamily: Fonts().primaryRegular }}
          >
            •{" "}
            <span style={{ marginLeft: 12 }}>
              Favoriser la qualité du contenu
            </span>
          </p>
          <p
            className="item__eeafrica"
            style={{ fontFamily: Fonts().primaryRegular }}
          >
            •{" "}
            <span style={{ marginLeft: 12 }}>
              Optimiser la vidéo pour le référencement
            </span>
          </p>
          <p
            className="item__eeafrica"
            style={{ fontFamily: Fonts().primaryRegular }}
          >
            • <span style={{ marginLeft: 12 }}>Promouvoir votre vidéo</span>
          </p>
          <br />
          <p
            className="coherence_visuelle_est_une_composante"
            style={{ fontFamily: Fonts().primaryRegular, color: "#df5c57" }}
          >
            Le marketing vidéo permet d’influencer positivement les décisions
            d'achat.
          </p>
        </div>
      </div>
      <div
        className="Definir_lidentite_visuelle_de_votre_entreprise"
        style={{ position: "relative" }}
      >
        <div className="image__fixed___second_place">
          <img
            alt="pic2"
            src={Pic2}
            className="pic2__fixed_image second__place"
            style={{ left: `${transformValue + 16}rem` }}
          />
        </div>
        <div className="image_fixed__third_place">
          <img
            className="pic5____"
            src={Pic5}
            style={{
              left: `${
                window.innerWidth <= 1200
                  ? transformValue + 7
                  : transformValue - 4
              }rem`,
            }}
          />
        </div>
        <div className="image_fixed__third_place__left">
          <img className="pic5____left" src={Pic5} />
        </div>

        <div className="second__wrapper__here">
          <img src={Pic3} className="image_fixed_3" />
          <p
            className="Definir_lidentite_visuelle_de_votre_entreprise_text"
            style={{ color: "#df5c57", fontFamily: Fonts().primaryBold }}
          >
            Définir l’identité visuelle de votre entreprise
          </p>
          <p
            className="role_cle_dans___text"
            style={{ fontFamily: Fonts().primaryRegular }}
          >
            Les éléments représentant l'image d’une marque, d’un produit ou
            d’une entreprise jouent un{" "}
            <strong>rôle clé dans la communication.</strong> Une identité
            visuelle forte et cohérente améliore la perception de la marque en
            créant une image professionnelle, sérieuse et fiable de votre
            entreprise. Elle{" "}
            <strong>favorise la reconnaissance de votre marque.</strong>
          </p>
          <div className="creation_cle_rolea_africa">
            <p
              className="item__eeafrica"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              •{" "}
              <span style={{ marginLeft: 12 }}>
                Création de Logo Professionnel{" "}
              </span>
            </p>
            <p
              className="item__eeafrica"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              •{" "}
              <span style={{ marginLeft: 12 }}>
                Conception de Charte Graphique sur Mesure{" "}
              </span>
            </p>
            <p
              className="coherence_visuelle_est_une_composante"
              style={{ fontFamily: Fonts().primaryRegular, color: "#df5c57" }}
            >
              La cohérence visuelle est une composante forte d’une stratégie
              marketing réussie.
            </p>
            <p
              className=" creer_votre_site_web_et_ou_votre_application_mobile"
              style={{ fontFamily: Fonts().primaryBold, color: "#df5c57" }}
            >
              Créer votre site web et/ou votre application mobile
            </p>
            <p
              className="la_creation_un_site_internet_et_dune_application_mobile"
              style={{ fontFamily: Fonts().primaryRegular }}
            >
              La création d'un site internet et d’une application mobile doivent
              respecter un processus matérialisé par diverses phases, chacune
              contribuant de manière significative à la construction d'un site
              ou d’une application mobile à la fois{" "}
              <strong>fonctionnel, esthétique et performant.</strong>
            </p>
          </div>
          <div
            className="planification_list_items"
            style={{ position: "relative" }}
          >
            <img className="pic4____" src={Pic4} />

            <div className="children_planification">
              <p
                className="planification_et_analyse_des_besoins"
                style={{ fontFamily: Fonts().primaryBold }}
              >
                Planification et analyse des besoins :
              </p>
              <p
                className="eeafrica_second_item__text"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <em>•</em>
                <span>
                  <span>
                    Définir les objectifs du site, déterminer le public cible,
                    et analyser les besoins spécifiques.
                  </span>
                </span>
              </p>
              <p
                className="eeafrica_second_item__text"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <em>•</em>
                <span>
                  <span>
                    Spécifier le contenu, les fonctionnalités et l’architecture
                    générale du site.
                  </span>
                </span>
              </p>
            </div>
            <div className="children_planification">
              <p
                className="planification_et_analyse_des_besoins"
                style={{ fontFamily: Fonts().primaryBold }}
              >
                Conception graphique et interface utilisateur :
              </p>
              <p
                className="eeafrica_second_item__text"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <em>•</em>
                <span>
                  <span>
                    Créer le design (mise en page, choix de couleurs, polices,
                    et expérience utilisateur).
                  </span>
                </span>
              </p>
              <p
                className="eeafrica_second_item__text"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <em>•</em>
                <span>
                  <span>Élaborer des maquettes et des prototypes.</span>
                </span>
              </p>
            </div>
            <div className="children_planification">
              <p
                className="planification_et_analyse_des_besoins"
                style={{ fontFamily: Fonts().primaryBold }}
              >
                Développement :
              </p>
              <p
                className="eeafrica_second_item__text"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <em>•</em>
                <span>
                  <span>Phase de création du site web.</span>
                </span>
              </p>
              <p
                className="eeafrica_second_item__text"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <em>•</em>
                <span>
                  <span>
                    Les développeurs utilisent des langages de programmation
                    pour construire le Front-end du site, le Back-end si besoin
                    ou recourir à des systèmes de gestion de contenu (CMS).
                  </span>
                </span>
              </p>
            </div>
            <div className="children_planification">
              <p
                className="planification_et_analyse_des_besoins"
                style={{ fontFamily: Fonts().primaryBold }}
              >
                Recette et validation :
              </p>
              <p
                className="eeafrica_second_item__text"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <em>•</em>
                <span>
                  <span>
                    Tester les fonctionnalités du site (vérification des liens,
                    des formulaires, des scripts, et la compatibilité avec les
                    navigateurs)
                  </span>
                </span>
              </p>
              <p
                className="eeafrica_second_item__text"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <em>•</em>
                <span>
                  <span>Tester la performance et la sécurité.</span>
                </span>
              </p>
            </div>
            <div className="children_planification">
              <p
                className="planification_et_analyse_des_besoins"
                style={{ fontFamily: Fonts().primaryBold }}
              >
                Lancement :
              </p>
              <p
                className="eeafrica_second_item__text"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <em>•</em>
                <span>
                  <span>
                    Mettre le site en ligne en le déployant sur un serveur web.
                  </span>
                </span>
              </p>
              <p
                className="eeafrica_second_item__text"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <em>•</em>
                <span>
                  <span>Configurer les domaines et le serveur web.</span>
                </span>
              </p>
            </div>

            <div className="children_planification">
              <p
                className="planification_et_analyse_des_besoins"
                style={{ fontFamily: Fonts().primaryBold }}
              >
                Maintenance et mise à jour :
              </p>
              <p
                className="eeafrica_second_item__text"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <em>•</em>
                <span>
                  <span>
                    Effectuer les mises à jour de sécurité et de contenu.
                  </span>
                </span>
              </p>
              <p
                className="eeafrica_second_item__text"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                <em>•</em>
                <span>
                  <span>
                    Réaliser des sauvegardes régulières et mettre à jour les
                    logiciels et plugins.
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="third__wrapper__full">
          <img className="pic5____response____" src={Pic3} />
          <div className="image_fixed__third_place warapper_second_one">
            <img
              className="pic5____ second_one"
              src={Pic3}
              style={{
                left: `${
                  transformValue +
                  (window.innerWidth <= 1350
                    ? window.innerWidth <= 1200
                      ? 12
                      : 8
                    : 6)
                }rem`,
              }}
            />
          </div>
          <div className="third__wrapper">
            <p
              className="votre_site_internet_est_le_prolongement_de_vos_campagnes"
              style={{ fontFamily: Fonts().primaryRegular, color: "#df5c57" }}
            >
              Votre site internet est le prolongement de vos campagnes
              marketing. Il constitue un outil de communication essentiel pour
              présenter vos produits, vos services, échanger avec vos clients et
              soutenir vos opérations marketing.{" "}
            </p>
            <p
              className="digital_maketing_title_eeafrica"
              style={{
                color: "#df5c57",
                fontFamily: Fonts().primarySemiBold,
              }}
            >
              Gestion de contenu
            </p>
            <p
              className="gestion_contenu_ou_ontent_management_est_un_processus"
              style={{
                fontFamily: Fonts().primaryRegular,
              }}
            >
              La gestion de contenu, ou Content Management, est un processus
              essentiel pour maintenir l'actualité, la pertinence et
              l'efficacité du contenu d'un site web, d'une plateforme numérique
              ou de tout autre support de communication. Ce processus dynamique
              comprend la création, la publication, l'organisation, et la mise à
              jour du contenu.
            </p>
            <img className="objectifs_list_2" src={objectifsList2} />
            <p
              className="gestion_contenu_ou_ontent_management_est_un_processus"
              style={{
                fontFamily: Fonts().primaryRegular,
              }}
            >
              La gestion de contenu est une stratégie dynamique exigeant une
              vigilance et une créativité constantes pour veiller que le contenu
              reste d’actualité, pertinent et en adéquation avec le public
              cible.
            </p>

            <p
              className="digital_maketing_title_eeafrica"
              style={{
                color: "#df5c57",
                fontFamily: Fonts().primarySemiBold,
                marginTop: 10,
              }}
            >
              Référencements
            </p>
            <p
              className="gestion_contenu_ou_ontent_management_est_un_processus"
              style={{
                fontFamily: Fonts().primaryRegular,
              }}
            >
              Le référencement d'un site web est un processus permettant
              d’améliorer sa visibilité d’un site dans les résultats des moteurs
              de recherche. L'objectif est d'attirer un trafic de qualité de
              manière organique (SEO) ou payante (SEA)
            </p>
          </div>
        </div>

        <img src={Référencements} className="referencement_boxes" />
      </div>
      <div className="conseil____container" style={{ position: "relative" }}>
        <img src={Pic12} className="picture12___-" />
        <div className="consiel__box_border">
          <div className="consiel__box_border_auto_height">
            <p
              className="consiel__text"
              style={{
                fontFamily: Fonts().primarySemiBold,
                color: "rgb(223, 92, 87)",
              }}
            >
              Un conseil :
            </p>
            <p
              className="consiel__text"
              style={{
                fontFamily: Fonts().primaryRegular,
              }}
            >
              Avant de faire appel à un cabinet. Il est essentiel de répondre à
              ces questions :
            </p>
            <p
              className="consiel__text"
              style={{
                fontFamily: Fonts().primaryRegular,
              }}
            >
              <span>•</span>
              <span>Pourquoi je veux lancer une campagne marketing ? </span>
            </p>
            <p
              className="consiel__text"
              style={{
                fontFamily: Fonts().primaryRegular,
              }}
            >
              <span>•</span>
              <span>Quels sont les objectifs que je souhaite atteindre ? </span>
            </p>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default Marketing;
