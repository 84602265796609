import React from "react";
import small3 from "@images/smalls/small3.png";
import { PendingIcon, Button } from "@components";
import { useColors, Fonts } from "@common";
import { useNavigate } from "react-router-dom";
import "./style.css";
import small4 from "@images/smalls/small4.png";

const { innerWidth, innerHeight } = window;

export const Items = ({ desc, strong, fontSize }) => {
  const Colors = useColors();
  return (
    <div className="section4__item">
      <PendingIcon width={15} height={15} />
      <p
        className="section4__item_desc"
        style={{
          fontSize: fontSize || "12px",
          fontFamily: Fonts().primaryRegular,
          color: Colors.blackText,
        }}
      >
        {" "}
        <strong
          style={{
            fontSize: fontSize || "12px",
            fontFamily: Fonts().primarySemiBold,
            color: Colors.blackText,
          }}
        >
          {strong}
        </strong>{" "}
        {desc}
      </p>
    </div>
  );
};

export default ({ RefFour }) => {
  const Colors = useColors();
  const navigate = useNavigate();
  return (
    <div className="externalisation_section__content__one" ref={RefFour}>
      <div className="externalisation_section_content_2">
        {innerWidth > 700 && (
          <div className="externalisation_smaøl3_div_image differnce__image">
            <img
              src={small4}
              className="externalisation_section_small_image_1"
            />
          </div>
        )}
        <div className="externalisation_section_content2_content">
          <h3
            className="externalisation_section__content__two__title"
            style={{
              fontSize: innerWidth > 600 ? "18px" : "16px",
              color: Colors.blackText,
              fontFamily:
                innerWidth > 600
                  ? Fonts().primaryBold
                  : Fonts().primarySemiBold,
            }}
          >
            Externalisation
          </h3>
          <p
            className="externalisation_section__content__two__desc"
            style={{
              fontSize: "14px",
              color: Colors.blackText,
              fontFamily: Fonts().primaryRegular,
              lineHeight: 1.7,
              opacity: 0.7,
              // marginTop: innerWidth < 414 ? 6 : 16,
            }}
          >
            Confiez-nous vos tâches périphériques les plus chronophages et
            gagnez en flexibilité et en productivite. Ces activités peuvent
            varier selon les entreprises, elles sont liées à la gestion
            financière (comptabilité, <span style={{color: "rgb(39, 48, 57)"}}>gestion</span>, <span style={{color: "rgb(39, 48, 57)"}}>facturation client</span>),
            l'administratif (<span style={{color: "rgb(39, 48, 57)"}}>rédaction des contrats</span> ,secrétariat), <span style={{color: "rgb(39, 48, 57)"}}>la RH
            (contrats, éléments variables de paie)</span> ou les services généraux
            <span style={{color: "rgb(39, 48, 57)"}}> expéditions, maintenance,... </span> <br/>
            <strong
              style={{
                fontFamily:
                  innerWidth > 600
                    ? Fonts().primaryBold
                    : Fonts().primaryRegular,
              }}
            >
              Quels sont les principaux avantages de l'externalisation ?
            </strong>
          </p>
          <div className="externalisation_section__items">
            <Items desc="Gain de temps" fontSize="13px" />
            <Items
              desc="Augmentation de la compétitivité et de la productivité"
              fontSize="13px"
            />
            <Items desc="Réduction des frais de personnel" fontSize="13px" />
            <Items desc="Recentrage sur le cœur de métier" fontSize="13px" />
            <Items desc="Souplesse opérationnelle" fontSize="13px" />

            <div className="externalisation_section__added_para">
              <p
                className="externalisation__section__added"
                style={{
                  fontFamily: Fonts().primaryRegular,
                  marginTop: "2%",
                  marginBottom: 10,
                  fontSize: innerWidth < 414 ? "10px" : "13px",
                }}
              >
                Concentrez vos ressources sur les missions de l’entreprise à
                plus forte valeur ajoutée...  <br />
                <span
                  style={{
                    color: Colors.error,
                  }}
                >
                  Sur votre cœur de métier !
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
