import React, { useRef, useState, useEffect } from "react";
import Pilotage from "@images/services_per_offer/Pilotage.png";
import Marketing from "@images/services_per_offer/marketing.png";
import Digitalisation from "@images/services_per_offer/Digitalisation.png";
import Externalisation from "@images/services_per_offer/Externalisation.png";
import "./index.css";
import { useColors, Fonts } from "@common";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import { useNavigate, useParams } from "react-router-dom";
import { Boxes, PendingIcon } from "@components";
import Increase from "@images/imgs/increase.png";
import database from "@images/imgs/database.png";
import laptop from "@images/imgs/laptop.png";
import planet from "@images/imgs/planet.png";
import marketing from "@images/imgs/marketing.png";
import hover_planet from "@images/imgs/hover_planet.png";
import hover_increase from "@images/imgs/hover_increase.png";
import hover_database from "@images/imgs/hover_database.png";

const DATA = [
  {
    id: 1,
    tag: "pilotage",
    title: "Pilotage de la performance",
    subTitle: "Prendre des décisions rapides",
    background: Pilotage,
    icon: null,
    desc: "Le pilotage de la performance est l’ensemble des outils et des processus permettant aux dirigeants d’améliorer la performance de leur entreprise. La mise en place d’un outil de pilotage de la performance permet de :",
    list: [
      { id: 1, text: "Transformer vos données en informations" },
      {
        id: 2,
        text: "Gagner du temps pour prioriser les activités à forte valeur ajoutée",
      },
      {
        id: 3,
        text: "Faire collaborer efficacement tous les départements de l’entreprise",
      },
      { id: 4, text: "Prendre des décisions rapides" },
    ],
  },
  {
    id: 2,
    tag: "marketing",
    title: "Marketing et Digital Marketing",
    subTitle:
      "Vous conseillent pour concevoir un site performant et visible. Recourir à nos services, c'est avant tout l'assurance d'obtenir un résultat conforme à vos exigences.",
    background: Marketing,
    icon: null,
    desc: "Le marketing digital utilise l’ensemble des canaux numériques pour vendre un produit ou un service auprès des consommateurs. Notre département web agency est spécialisé dans la création de site internet, le développement web, le développement d'application mobile ou encore le référencement naturel (SEO) ou publicité (SEA). Du conseil à la réalisation, nos experts",
    list: [
      { id: 1, text: "Chef de projet" },
      { id: 2, text: "Développeurs (front-end et back-end)" },
      { id: 3, text: "Designer UX/UI" },
    ],
  },
  {
    id: 3,
    tag: "digitalisation",
    title: "Digitalisation des processus métier",
    subTitle:
      "La digitalisation des processus apporte de nombreux avantages à l’entreprise et à sa chaîne de valeur.",
    background: Digitalisation,
    icon: null,
    desc: "La digitalisation au service de la performance des entreprises. Une solution de transformation numérique des organisations, qui concerne toutes les fonctions de l'entreprise.",
    list: [
      { id: 1, text: "les processus financiers et comptables" },
      { id: 2, text: "les processus de Ressources Humaines" },
      { id: 3, text: "les processus d’achats" },
      { id: 4, text: "les processus commerciaux et marketing" },
    ],
  },
  {
    id: 4,
    tag: "externalisation",
    title: "Externalisation",
    subTitle:
      "Concentrez vos ressources sur les missions de l’entreprise à plus forte valeur ajoutée... Sur votre cœur de métier !",
    background: Externalisation,
    icon: null,
    desc: "Confiez-nous vos tâches périphériques les plus chronophages et gagner en productivité et en flexibilité. Ces activités peuvent varier selon les entreprises, elles sont liées à la gestion financière (comptabilité), l’administratif (secrétariat) ou les services généraux (expéditions, maintenance, …). Les principaux avantages de l’externalisation :",
    list: [
      { id: 1, text: "Gain de temps" },
      { id: 2, text: "Augmentation de la compétitivité et de la productivité" },
      { id: 3, text: "Réduction des frais de personnel" },
      { id: 4, text: "Souplesse opérationnelle" },
    ],
  },
];

const TEXT = (
  <span>
    Pilotage <br /> de la performance
  </span>
);
const TEXT2 = (
  <span>
    Digitalisation <br /> des processus métier
  </span>
);

export const Items = ({ desc, strong, fontSize }) => {
  const Colors = useColors();
  return (
    <div className="section4__item">
      <PendingIcon width={15} height={15} />
      <p
        className="section4__item_desc"
        style={{
          fontSize: fontSize || "12px",
          fontFamily: Fonts().primaryRegular,
          color: Colors.blackText,
        }}
      >
        {" "}
        <strong
          style={{
            fontSize: fontSize || "12px",
            fontFamily: Fonts().primarySemiBold,
            color: Colors.blackText,
          }}
        >
          {strong}
        </strong>{" "}
        {desc}
      </p>
    </div>
  );
};

export default ({}) => {
  const [isClicked, setIsClicked] = useState({ id: null, clicked: false });
  const [isClickedTaped, setIsClickedTaped] = useState(false);
  const [theData, setTheData] = useState(null);
  const [idBox, setIdBox] = useState(null);
  const params = useParams();
  const RefOne = useRef();
  const RefTwo = useRef();
  const RefThree = useRef();
  const RefFour = useRef();
  const RefFive = useRef();
  const RefSex = useRef();
  const RefSeven = useRef();
  const RefEight = useRef();
  const { name } = params;
  const Colors = useColors();
  const section2Ref = useRef(null);
  const [active, setActive] = useState("services");
  const navigate = useNavigate();

  useEffect(() => {
    if (!!name) {
      const findTheData = DATA.find((ele) => ele.tag === name);
      setIsClicked({ id: findTheData?.id, clicked: true });
      setIdBox(findTheData?.id);
      setTheData(findTheData);
    }
  }, [name]);

  const OurServices = () => {
    setActive("services");
    section2Ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const hanleOnclickHome = () => {
    setActive("Accueil");
    // Home2Ref.current.scrollIntoView({ behavior: "smooth" });
    navigate("/");
  };

  const handleOnClickContact = () => {
    setActive("Contacts");
    navigate(`/${"contacts"}`);
  };
  const handleOnClickAbout = () => {
    setActive("Abouts");
    navigate(`/${"abouts"}`);
  };

  const onMouseEnter = (id) => {
    setIsClicked({ id: id, clicked: true });
    setIdBox(id);
  };
  const onMouseLeave = (id) => {
    if (!isClickedTaped) {
      const findTheData = DATA.find((ele) => ele.tag === name);
      setIsClicked({ id: findTheData?.id, clicked: true });
      setIdBox(findTheData?.id);
      setTheData(findTheData);
    } else {
      const findTheData = DATA.find((ele) => ele.tag === findTheData?.tag);
      setIsClicked({ id: findTheData?.id, clicked: true });
      setIdBox(findTheData?.id);
      setTheData(findTheData);
    }
  };

  const handleClicked = (id) => {
    setIsClicked({ id: id, clicked: true });
    setIdBox(id);
    var theName = null;
    if (id === 1) {
      theName = "pilotage";
    } else if (id === 2) {
      theName = "marketing";
    } else if (id === 3) {
      theName = "digitalisation";
    } else {
      theName = "externalisation";
    }
    navigate(`/nos_services/details/${theName}`);
    // setIsClickedTaped(true);
    // const findTheData = DATA.find((ele) => ele.tag === theName);
    // setTheData(findTheData);
  };

  return (
    <div className="home__home" style={{ background: Colors.backgroundHex }}>
      <NavBar
        handleOurService={OurServices}
        active={active}
        hanleOnclickHome={hanleOnclickHome}
        handleOnClickContact={handleOnClickContact}
        handleOnClickAbout={handleOnClickAbout}
      />
      <div
        style={{
          backgroundImage: `url(${theData?.background})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          position: "relative",
          height: "850px",
        }}
        className="section3__main"
      >
        <div className="separed_section_global">
          <div className="the_header_separed_section">
            <h1
              className="separated_title_global"
              style={{ fontFamily: Fonts().primarySemiBold }}
            >
              Une offre construite autour du Digital
            </h1>
          </div>
          <div className="separed_section_boxes">
            <div className="section2__center__content">
              <Boxes
                topText={TEXT}
                image1={Increase}
                image2={hover_increase}
                // bottomText="Lorem ipsum dolor, act screen 1500's evolution tech"
                isClicked={isClicked}
                handleClicked={() => handleClicked(1)}
                id={1}
                onMouseEnter={() => onMouseEnter(1)}
                onMouseLeave={() => onMouseLeave(1)}
              />
              <Boxes
                topText="Marketing digital"
                image2={laptop}
                image1={marketing}
                // bottomText="Lorem ipsum dolor, act screen 1500's evolution tech"
                isClicked={isClicked}
                handleClicked={() => handleClicked(2)}
                id={2}
                onMouseEnter={() => onMouseEnter(2)}
                onMouseLeave={() => onMouseLeave(2)}
              />
              <Boxes
                topText={TEXT2}
                image1={database}
                image2={hover_database}
                // bottomText="Lorem ipsum dolor, act screen 1500's evolution tech"
                isClicked={isClicked}
                handleClicked={() => handleClicked(3)}
                onMouseEnter={() => onMouseEnter(3)}
                onMouseLeave={() => onMouseLeave(3)}
                id={3}
              />
              <Boxes
                topText="Externalisation"
                image1={planet}
                image2={hover_planet}
                // bottomText="Lorem ipsum dolor, act screen 1500's evolution tech"
                isClicked={isClicked}
                handleClicked={() => handleClicked(4)}
                id={4}
                onMouseEnter={() => onMouseEnter(4)}
                onMouseLeave={() => onMouseLeave(4)}
              />
            </div>
          </div>
          <div className="separed_section_displaied_data">
            <div className="fetched_data">
              <h1
                className="fetched_data_title"
                style={{ fontFamily: Fonts().primaryBold, color: Colors.error }}
              >
                {theData?.title}
              </h1>
              <h3
                className="fetched_data_title"
                style={{ fontFamily: Fonts().primarySemiBold }}
              >
                {theData?.subTitle}
              </h3>
              <p
                className="fetched_data_title"
                style={{ fontFamily: Fonts().primaryRegular }}
              >
                {theData?.desc}
              </p>

              {theData?.list.map((ele) => (
                <div
                  className="separed_section_marketing_section__items"
                  key={ele.id}
                >
                  <Items desc={ele?.text} fontSize="13px" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
